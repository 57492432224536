@use "./variables" as *;
.resume{
    display: flex;
    align-items: center;
    justify-content: center;
}
.welcome{
  text-align: center;   
  font-family: cursive;
  font-size: 4rem;

}
