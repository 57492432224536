@use './variables' as *;
.work{
	font-weight: bold;
	font-size: 30px;
	text-decoration: underline;
}
.side{
	font-weight: bold;
	font-size: 15px;
}
.duties{
	list-style-type: square;
}
.jobs{
	border-radius: 3rem;
	border-width: 1rem;
	display: inline;
	background: none;
	margin: clamp(0.5rem,50%,1rem);
	padding: clamp(0.5rem,50%,1rem);
}