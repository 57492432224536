@use './variables' as *;
/*main text area*/
article{
	@media screen and (max-width:720px){
		font-size: 13px;
	}
}


h1{
	font-size: 3rem;
    text-align: $cent;
	font-style: italic;
}

.about{
	line-height: 40px;
}

.underline{
	font-size: 2rem;
	text-decoration: underline;	
}
img{
	width: 250px;
	height: 300px;
	margin: 1rem;

}