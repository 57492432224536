:root {
    --light-body-bg: #FFF;
    --light-text-color: #000;
    --light-nav-bg: #f8f9fa;
    --light-nav-text: #000;
    --light-link-color: #1000f3;
    --light-link-hover: #1c7bf8;
    --light-link-clicked: #580092;
  
    --dark-body-bg: #363537;
    --dark-text-color: #FAFAFA;
    --dark-nav-bg: #3a3b3c;
    --dark-nav-text: #FAFAFA;
    --dark-link-color: #3097ec;
     --dark-link-hover: #76b9f0;
     --dark-link-clicked: #8149a7;
  }
  
  body {
    background-color: var(--body-bg);
    color: var(--text-color);
    font-family: Arial, Helvetica, sans-serif;
    transition: all 0.5s ease;
  }
  a {
    color: var(--link-color);
    text-decoration: underline;
    transition: color 0.3s ease;
  
    &:hover {
      color: var(--link-hover);
    }
    &:visited{
        color: var(--link-clicked);
    }
  }
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
  }
  .copyright{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  