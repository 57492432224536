@use "./variables" as *;
.error-message {
  color: #be4a4a;
  font-size: 3rem;
}
.error-image {
  color: #580404;
  width: 10rem;
  height: 10rem;
  display: block;
  margin-left: clamp(37%, 50%, 42%);
  margin-right: auto;
  animation: out 3s linear infinite;
}
@keyframes out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
