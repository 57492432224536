.navMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--nav-bg);
    color: var(--nav-text);
  
    .menu-icon {
      display: none;
      font-size: 2rem;
      cursor: pointer;
  
      @media (max-width: 768px) {
        display: block;
      }
    }
  
    .navList {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
  
      li {
        margin: 0 1rem;
  
        a {
          color: var(--nav-text);
          text-decoration: none;
        }
      }
  
      @media (max-width: 768px) {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: var(--nav-bg);
  
        &.active {
          display: flex;
        }
  
        li {
          width: 100%;
          text-align: center;
          padding: 1rem 0;
        }
      }
    }
  }
  
