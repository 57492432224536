@use "./variables" as *;
@use "./light";
.skip-nav{
	position:absolute;
	transform: translateY(-120%);
	left: 0.5rem;
	transition:transform 250ms ease-in;
	&:link:focus{
		transform: translateY(0);
	}
}

html {
  scroll-behavior: smooth;
}
.italic {
  font-style: italic;
}
