@use './variables' as *;
.contact{
    text-align: left;
    @media screen and (max-width:720px){
        text-align: left;
    }
}
.contact_input{
    margin: 10px;
    width: clamp(17.5rem,50%,40rem);
    display: flex;
    align-items: start;
    justify-content: start;
}
.contact_input.placeholder{
    color: #202020;
}